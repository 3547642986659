import { Redirect, Router } from '@reach/router';
import React, { memo, useEffect } from 'react';
import { NavigationProgress } from '@mantine/nprogress';
//import OpenReplay from '@openreplay/tracker';
//import trackerAssist from '@openreplay/tracker-assist';
import Builder from './app/builder';
import Dashboard from './app/dashboard';
import NotFound from './404';
import PrivateRoute from '../components/router/PrivateRoute';
import Wrapper from '../components/shared/Wrapper';
import Home from './login';

/*
const tracker = new OpenReplay({
  projectKey: "lYq9ZX86tIO39ffz1SKK",
  ingestPoint: "https://relay.boardsi.com/ingest",
});

*/

const App = () => {
  /*
  useEffect(()=>{
    tracker.use(trackerAssist({}));
    tracker.start();
  }, []);
  */

  return (
    <Wrapper>
      <NavigationProgress />
      <Router>
        <Home path="/login/callback" exact/>
        <Home path="/login"/>
        <Redirect noThrow from="/app" to="/app/dashboard" exact />
        <PrivateRoute path="/app/dashboard" component={Dashboard} />
        <PrivateRoute path="/app/builder/:id" component={Builder} />
        <NotFound default />
      </Router>
    </Wrapper>
  );
};

export default memo(App);

export async function getServerData() {}
