import { navigate } from 'gatsby';
import React, { memo, useContext, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';
import UserContext from '../../contexts/UserContext';

import {delay} from '../../utils';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { user, loading, verifySession, validSession } = useContext(UserContext);

  /*
  if (loading) {
    return <LoadingScreen />;
  }
  */

  /*
  if (!user) {
    navigate('/login');
    return null;
  }
  */

  if ( validSession ) {
    return (<Component user={user} {...props} />);
  }

  return (<Component user={user} {...props} />);
};

export default memo(PrivateRoute);
